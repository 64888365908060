import type * as Types from './types';

import {
  useQuery,
  type QueryClient,
  type FetchQueryOptions,
  type UseQueryOptions,
} from '@tanstack/react-query';
import type { DXError } from '@dx-ui/types-graphql';
export const Hotel_Link_Info_FragmentFragmentDoc = `
    fragment HOTEL_LINK_INFO_FRAGMENT on HotelLink {
  _id
  url
  label
  isNewWindow
  adaDescription
}
    `;
export const RoomFragmentFragmentDoc = `
    fragment RoomFragment on HotelRoomType {
  accommodationCode
  roomTypeCode
  roomTypeName @toTitleCase
  desc: customDescription
  name: roomTypeName @toTitleCase
  virtualMedia(filter: {assetType_in: [photo_360, model_3d]}) {
    _id
    assetType
    assetUrl
    roomTypeCode
  }
  highlights: features(
    filter: {id_in: [1002, 176, 34, 151, 71, 146, 153, 5, 168, 169, 7, 6, 152, 54, 116, 170, 167, 148, 178, 23, 154, 13, 121, 90, 223, 135, 79, 204, 205, 120, 78, 187, 80, 81, 188, 189, 190, 221, 197, 191, 198, 192, 193, 194, 195, 196, 8, 16, 19, 122, 89, 3, 82, 31, 53, 14, 11, 12]}
    first: 8
  ) {
    name
  }
  thumbnail: carousel(variant: roomImageProperty, first: 1) {
    altText
    variants {
      size
      url
    }
  }
  carousel: carousel(variant: roomImagePropertyExpanded) {
    altText
    variants {
      size
      url
    }
  }
  confidenceAmenities: features(filter: {category: confidence}) {
    name
  }
  comfortAmenities: features(filter: {category: comfort}) {
    name
  }
  convenienceAmenities: features(filter: {category: convenience}) {
    name
  }
}
    `;
export const Hotel_Service_Amenity_InfoFragmentDoc = `
    fragment HOTEL_SERVICE_AMENITY_INFO on HotelServiceAmenity {
  name
  category
  distance
  distanceFmt
  distanceUnit
}
    `;
export const Hotel_Image_Info_FragmentFragmentDoc = `
    fragment HOTEL_IMAGE_INFO_FRAGMENT on HotelImage {
  altText
  variants {
    size
    url
  }
}
    `;
export const Golf_Gallery_FragmentFragmentDoc = `
    fragment GOLF_GALLERY_FRAGMENT on HotelImages {
  golfSignatureImages: gallery(
    filter: {hotelFeatures_includes: "Golf", signatureImage: true}
    first: 1
  ) {
    image(variant: fullWidthCarousel) {
      ...HOTEL_IMAGE_INFO_FRAGMENT
    }
  }
  golfGallery: gallery(filter: {hotelFeatures_includes: "Golf"}) {
    _id
    categories
    hotelFeatures_noTx: hotelFeatures
    roomFeatures_noTx: roomFeatures
    masterImage
    fullImage: image(variant: galleryExpanded) {
      ...HOTEL_IMAGE_INFO_FRAGMENT
    }
    thumbImage: image(variant: galleryThumbnail) {
      ...HOTEL_IMAGE_INFO_FRAGMENT
    }
  }
}
    ${Hotel_Image_Info_FragmentFragmentDoc}`;
export const Hotel_Airport_InfoFragmentDoc = `
    fragment HOTEL_AIRPORT_INFO on HotelAirport {
  name
  code
  distanceFmtMiles: distanceFmt(distanceUnit: mi, decimal: 1)
  distanceFmtKm: distanceFmt(distanceUnit: km, decimal: 1)
  defaultDistanceUnit
  distanceFmt
  distance
  transportations(filter: {id: "C"}) {
    _id
    type
    desc
    time
    charge
  }
}
    `;
export const Hotel_Coordinate_InfoFragmentDoc = `
    fragment HOTEL_COORDINATE_INFO on HotelCoordinate {
  latitude
  longitude
  latitudeFmt
  longitudeFmt
}
    `;
export const Hotel_Restaurant_Hours_Operation_FragmentFragmentDoc = `
    fragment HOTEL_RESTAURANT_HOURS_OPERATION_FRAGMENT on HotelRestaurant {
  hoursOfOperation {
    monday {
      open
      close
    }
    tuesday {
      open
      close
    }
    wednesday {
      open
      close
    }
    thursday {
      open
      close
    }
    friday {
      open
      close
    }
    saturday {
      open
      close
    }
    sunday {
      open
      close
    }
  }
}
    `;
export const Hotel_Meetings_FragmentFragmentDoc = `
    fragment HOTEL_MEETINGS_FRAGMENT on Hotel {
  meetings {
    hiltonLinkUrl
    eEventsUrl
    rfpUrl
    headline
    desc
    numberOfMeetingRooms
    largestMeetingRoomFeet: largestMeetingRoomAreaFmt(
      decimal: 0
      language: "en"
      strategy: round
      unit: feet
    )
    largestMeetingRoomMeters: largestMeetingRoomAreaFmt(
      decimal: 0
      language: "en"
      strategy: round
      unit: meter
    )
    totalAreaFeet: totalMeetingRoomsAreaFmt(
      decimal: 0
      language: "en"
      strategy: round
      unit: feet
    )
    totalAreaMeters: totalMeetingRoomsAreaFmt(
      decimal: 0
      language: "en"
      strategy: round
      unit: meter
    )
    meetingRooms {
      name_noTx: name
      areaFeet: areaFmt(decimal: 0, language: "en", unit: feet)
      areaMeters: areaFmt(decimal: 0, language: "en", unit: meter)
      includeInTtlArea
      configurationCapacities {
        banquet
        reception
        conference
        hollowSquare
        classroom
        theater
        uShaped
      }
    }
    places {
      name
      desc
      link {
        adaDescription
        isNewWindow
        label
        url
      }
      image(variant: halfAndHalf) {
        altText
        variants {
          size
          url
        }
      }
    }
    image(variant: fullWidthCarousel) {
      altText
      variants {
        size
        url
      }
    }
  }
}
    `;
export const Hotel_Parking_Info_FragmentFragmentDoc = `
    fragment HOTEL_PARKING_INFO_FRAGMENT on HotelParking {
  hasCoveredParking
  hasSelfParking
  hasValetParking
  otherParkingInfo
  hasOnsiteParking
  hasSecuredParking
  hasInOutParkingPrivilege
  valetParkingCharge {
    chargeAmount
    chargeAmountFmt
    parkingComments
  }
  selfParkingCharge {
    chargeAmount
    chargeAmountFmt
    chargeQuantifier
    parkingComments
  }
  valetParkingCharge {
    chargeAmount
    chargeAmountFmt
    parkingComments
  }
  evCharging {
    chargingComments
    chargingUnitDistance
    chargingUnitDistanceFmt
    chargingUnitDistanceUOM
    hasChargingNearby
    hasChargingOnsite
  }
}
    `;
export const Hotel_Pets_Info_FragmentFragmentDoc = `
    fragment HOTEL_PETS_INFO_FRAGMENT on HotelPets {
  petsAllowed
  servicePetsAllowed
  petMaxSize
  description
  otherServiceDesc
  petChargeRefundable
  servicePetsDesc
  petMaxWeight {
    unitOfMass
    amount
    amountFmt
  }
  petMaxWeightFmt
  petCharge
  petChargeFmt
  otherServiceDesc
  petChargeRefundable
  servicePetsDesc
}
    `;
export const Hotel_Registration_Info_FragmentFragmentDoc = `
    fragment HOTEL_REGISTRATION_INFO_FRAGMENT on HotelRegistration {
  _id
  earlyDepartureFee
  earlyDepartureFeeFmt
  earlyDepartureFeeType
  earlyDepartureText
  earlyDepartureText_noTx: earlyDepartureText
  checkinTimeFmt
  checkoutTimeFmt
  lateCheckoutFee
  lateCheckoutFeeFmt
  lateCheckoutText
  lateCheckoutText_noTx: lateCheckoutText
  minAgeToRegister
}
    `;
export const Meetings_Gallery_FragmentFragmentDoc = `
    fragment MEETINGS_GALLERY_FRAGMENT on HotelImages {
  meetingGallery: gallery(filter: {categories_includes: meetingSpace}) {
    image(variant: fullWidthCarousel) {
      altText
      variants {
        size
        url
      }
    }
  }
  meetingsEventsOverview: gallery(
    first: 1
    filter: {categories_includes: meetingSpace}
  ) {
    image(variant: fullWidthCarousel) {
      altText
      variants {
        size
        url
      }
    }
  }
}
    `;
export const Spa_Gallery_FragmentFragmentDoc = `
    fragment SPA_GALLERY_FRAGMENT on HotelImages {
  spaSignatureImages: gallery(
    filter: {hotelFeatures_includes: "Spa", signatureImage: true}
    first: 1
  ) {
    image(variant: fullWidthCarousel) {
      altText
      variants {
        size
        url
      }
    }
  }
  spaGallery: gallery(filter: {hotelFeatures_includes: "Spa"}) {
    _id
    categories
    hotelFeatures_noTx: hotelFeatures
    roomFeatures_noTx: roomFeatures
    masterImage
    fullImage: image(variant: galleryExpanded) {
      altText
      variants {
        size
        url
      }
    }
    thumbImage: image(variant: galleryThumbnail) {
      altText
      variants {
        size
        url
      }
    }
  }
}
    `;
export const Trip_Advisor_FragmentFragmentDoc = `
    fragment TRIP_ADVISOR_FRAGMENT on Hotel {
  tripAdvisorLocationSummary {
    _id
    name
    rating
    ratingImageUrl
    numReviews
    reviews {
      id
      lang
      locationId
      publishedDate
      rating
      helpfulVotes
      ratingImageUrl
      url
      text
      travelDate
      user {
        username
      }
      title
    }
  }
}
    `;
export const FeatureTogglesDocument = {
  operationName: 'featureConfigs_featureToggles',
  operationString: `query featureConfigs_featureToggles($featureToggles: [String!]!, $featureConfigs: [String!]!) {
  featureToggles(names: $featureToggles) {
    _id
    name
    enabled
  }
  featureConfigs(names: $featureConfigs) {
    name
    config
  }
}`,
  originalOpName: 'featureToggles',
};
export function useFeatureTogglesQuery<TData = Types.FeatureTogglesQuery, TError = DXError>(
  variables: Types.FeatureTogglesQueryVariables,
  options?: Omit<UseQueryOptions<Types.FeatureTogglesQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.FeatureTogglesQuery, TError, TData>({
    queryKey: [FeatureTogglesDocument, variables],
    ...options,
  });
}
export function serverSideFeatureTogglesQuery(
  queryClient: QueryClient,
  variables: Types.FeatureTogglesQueryVariables,
  options?: FetchQueryOptions<Types.FeatureTogglesQuery>
) {
  return queryClient.fetchQuery<Types.FeatureTogglesQuery>({
    queryKey: [FeatureTogglesDocument, variables],
    ...options,
  });
}

export const HomepageShopDocument = {
  operationName: 'hotel_shopPropAvail',
  operationString: `query hotel_shopPropAvail($arrivalDate: String!, $ctyhocn: String!, $departureDate: String!, $language: String!, $guestLocationCountry: String, $numAdults: Int!, $numChildren: Int!, $numRooms: Int!, $displayCurrency: String, $specialRates: ShopSpecialRateInput) {
  hotel(ctyhocn: $ctyhocn, language: $language) {
    ctyhocn
    shopAvail(
      input: {guestLocationCountry: $guestLocationCountry, arrivalDate: $arrivalDate, departureDate: $departureDate, displayCurrency: $displayCurrency, numAdults: $numAdults, numChildren: $numChildren, numRooms: $numRooms, specialRates: $specialRates}
    ) {
      currencyCode
      summary {
        status {
          type
        }
        lowest {
          roomTypeCode
          ratePlanCode
          rateAmount
          rateAmountFmt(decimal: 0, strategy: trunc)
          serviceChargeDetails
        }
      }
    }
  }
}`,
  originalOpName: 'HomepageShop',
};
export function useHomepageShopQuery<TData = Types.HomepageShopQuery, TError = DXError>(
  variables: Types.HomepageShopQueryVariables,
  options?: Omit<UseQueryOptions<Types.HomepageShopQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.HomepageShopQuery, TError, TData>({
    queryKey: [HomepageShopDocument, variables],
    ...options,
  });
}
export function serverSideHomepageShopQuery(
  queryClient: QueryClient,
  variables: Types.HomepageShopQueryVariables,
  options?: FetchQueryOptions<Types.HomepageShopQuery>
) {
  return queryClient.fetchQuery<Types.HomepageShopQuery>({
    queryKey: [HomepageShopDocument, variables],
    ...options,
  });
}

export const GetHotelAlertsDocument = {
  operationName: 'hotelPageTemplate',
  operationString: `query hotelPageTemplate($ctyhocn: String!) {
  hotelPageTemplate(ctyhocn: $ctyhocn, language: "en") {
    hotel {
      alerts {
        _id
        type
        name
        headline
        description
        startDateTime
        endDateTime
        link {
          ...HOTEL_LINK_INFO_FRAGMENT
        }
      }
    }
  }
}

      ${Hotel_Link_Info_FragmentFragmentDoc}`,
  originalOpName: 'getHotelAlerts',
};
export function useGetHotelAlertsQuery<TData = Types.GetHotelAlertsQuery, TError = DXError>(
  variables: Types.GetHotelAlertsQueryVariables,
  options?: Omit<UseQueryOptions<Types.GetHotelAlertsQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.GetHotelAlertsQuery, TError, TData>({
    queryKey: [GetHotelAlertsDocument, variables],
    ...options,
  });
}
export function serverSideGetHotelAlertsQuery(
  queryClient: QueryClient,
  variables: Types.GetHotelAlertsQueryVariables,
  options?: FetchQueryOptions<Types.GetHotelAlertsQuery>
) {
  return queryClient.fetchQuery<Types.GetHotelAlertsQuery>({
    queryKey: [GetHotelAlertsDocument, variables],
    ...options,
  });
}

export const StayIncludesAmenitiesDocument = {
  operationName: 'StayIncludesAmenities',
  operationString: `query StayIncludesAmenities($ctyhocn: String!, $language: String!) {
  hotel(ctyhocn: $ctyhocn, language: $language) {
    stayIncludesAmenities: amenities(filter: {groups_includes: [stay]}) {
      id
      name
    }
  }
}`,
  originalOpName: 'StayIncludesAmenities',
};
export function useStayIncludesAmenitiesQuery<
  TData = Types.StayIncludesAmenitiesQuery,
  TError = DXError
>(
  variables: Types.StayIncludesAmenitiesQueryVariables,
  options?: Omit<UseQueryOptions<Types.StayIncludesAmenitiesQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.StayIncludesAmenitiesQuery, TError, TData>({
    queryKey: [StayIncludesAmenitiesDocument, variables],
    ...options,
  });
}
export function serverSideStayIncludesAmenitiesQuery(
  queryClient: QueryClient,
  variables: Types.StayIncludesAmenitiesQueryVariables,
  options?: FetchQueryOptions<Types.StayIncludesAmenitiesQuery>
) {
  return queryClient.fetchQuery<Types.StayIncludesAmenitiesQuery>({
    queryKey: [StayIncludesAmenitiesDocument, variables],
    ...options,
  });
}

export const GetHotelDiningPageDocument = {
  operationName: 'getHotelDiningPage',
  operationString: `query getHotelDiningPage($ctyhocn: String!) {
  hotel(ctyhocn: $ctyhocn, language: "en", input: {cmsDocRequired: true}) {
    brandCode
    name
    restaurantOverview {
      desc
      headline
      image {
        ...HOTEL_IMAGE_INFO_FRAGMENT
      }
      diningOverviewImage: image(variant: diningOverview) {
        ...HOTEL_IMAGE_INFO_FRAGMENT
      }
      halfAndHalfImage: image(variant: halfAndHalf) {
        ...HOTEL_IMAGE_INFO_FRAGMENT
      }
      restaurants(enhance: true, filter: {onSite: true}) {
        headline
        description
        name_noTx: name
        onSite
        awards {
          name
        }
        contactDetails {
          information
          type
        }
        expandedImage: images(variant: diningExpanded) {
          altText
          variants {
            size
            url
          }
        }
        link {
          ...HOTEL_LINK_INFO_FRAGMENT
        }
        halfAndHalfImages: images(variant: halfAndHalf) {
          altText
          variants {
            size
            url
          }
        }
        overviewImage: images(variant: diningOverview) {
          altText
          variants {
            size
            url
          }
        }
        diningMenus_noTx: diningMenus {
          name
          desc
          sections {
            name
            desc
            items {
              name
              desc
              priceFmt
              allergens
              additionalDisclaimers
              dietaryInformation
              dietaryInformation_noTx: dietaryInformation
              calories
            }
          }
        }
        ...HOTEL_RESTAURANT_HOURS_OPERATION_FRAGMENT
      }
    }
  }
}

      ${Hotel_Image_Info_FragmentFragmentDoc}
${Hotel_Link_Info_FragmentFragmentDoc}
${Hotel_Restaurant_Hours_Operation_FragmentFragmentDoc}`,
  originalOpName: 'getHotelDiningPage',
};
export function useGetHotelDiningPageQuery<TData = Types.GetHotelDiningPageQuery, TError = DXError>(
  variables: Types.GetHotelDiningPageQueryVariables,
  options?: Omit<UseQueryOptions<Types.GetHotelDiningPageQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.GetHotelDiningPageQuery, TError, TData>({
    queryKey: [GetHotelDiningPageDocument, variables],
    ...options,
  });
}
export function serverSideGetHotelDiningPageQuery(
  queryClient: QueryClient,
  variables: Types.GetHotelDiningPageQueryVariables,
  options?: FetchQueryOptions<Types.GetHotelDiningPageQuery>
) {
  return queryClient.fetchQuery<Types.GetHotelDiningPageQuery>({
    queryKey: [GetHotelDiningPageDocument, variables],
    ...options,
  });
}

export const GetHotelEventsPageDocument = {
  operationName: 'getHotelEventsPage',
  operationString: `query getHotelEventsPage($ctyhocn: String!) {
  hotel(ctyhocn: $ctyhocn, language: "en") {
    brandCode
    hideGroupsBlock
    totalRooms
    address {
      country
    }
    images {
      ...MEETINGS_GALLERY_FRAGMENT
    }
    ...HOTEL_MEETINGS_FRAGMENT
  }
}

      ${Meetings_Gallery_FragmentFragmentDoc}
${Hotel_Meetings_FragmentFragmentDoc}`,
  originalOpName: 'getHotelEventsPage',
};
export function useGetHotelEventsPageQuery<TData = Types.GetHotelEventsPageQuery, TError = DXError>(
  variables: Types.GetHotelEventsPageQueryVariables,
  options?: Omit<UseQueryOptions<Types.GetHotelEventsPageQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.GetHotelEventsPageQuery, TError, TData>({
    queryKey: [GetHotelEventsPageDocument, variables],
    ...options,
  });
}
export function serverSideGetHotelEventsPageQuery(
  queryClient: QueryClient,
  variables: Types.GetHotelEventsPageQueryVariables,
  options?: FetchQueryOptions<Types.GetHotelEventsPageQuery>
) {
  return queryClient.fetchQuery<Types.GetHotelEventsPageQuery>({
    queryKey: [GetHotelEventsPageDocument, variables],
    ...options,
  });
}

export const GetHotelGalleryPageDocument = {
  operationName: 'getHotelGalleryPage',
  operationString: `query getHotelGalleryPage($ctyhocn: String!) {
  hotel(ctyhocn: $ctyhocn, language: "en") {
    images {
      gallery(sort: {by: masterImage, order: desc}) {
        _id
        categories
        hotelFeatures_noTx: hotelFeatures
        roomFeatures_noTx: roomFeatures
        masterImage
        fullImage: image(variant: galleryExpanded) {
          altText
          variants {
            size
            url
          }
        }
        thumbImage: image(variant: galleryThumbnail) {
          _id
          altText
          variants {
            size
            url
          }
        }
      }
      ...MEETINGS_GALLERY_FRAGMENT
      ...GOLF_GALLERY_FRAGMENT
      ...SPA_GALLERY_FRAGMENT
    }
    facilityOverview {
      isWorldwideResort
    }
  }
}

      ${Meetings_Gallery_FragmentFragmentDoc}
${Golf_Gallery_FragmentFragmentDoc}
${Spa_Gallery_FragmentFragmentDoc}`,
  originalOpName: 'getHotelGalleryPage',
};
export function useGetHotelGalleryPageQuery<
  TData = Types.GetHotelGalleryPageQuery,
  TError = DXError
>(
  variables: Types.GetHotelGalleryPageQueryVariables,
  options?: Omit<UseQueryOptions<Types.GetHotelGalleryPageQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.GetHotelGalleryPageQuery, TError, TData>({
    queryKey: [GetHotelGalleryPageDocument, variables],
    ...options,
  });
}
export function serverSideGetHotelGalleryPageQuery(
  queryClient: QueryClient,
  variables: Types.GetHotelGalleryPageQueryVariables,
  options?: FetchQueryOptions<Types.GetHotelGalleryPageQuery>
) {
  return queryClient.fetchQuery<Types.GetHotelGalleryPageQuery>({
    queryKey: [GetHotelGalleryPageDocument, variables],
    ...options,
  });
}

export const GetHotelGolfPageDocument = {
  operationName: 'getHotelGolfPage',
  operationString: `query getHotelGolfPage($ctyhocn: String!) {
  hotel(ctyhocn: $ctyhocn, language: "en") {
    brandCode
    images {
      ...GOLF_GALLERY_FRAGMENT
    }
    golf {
      desc
      headline
      name
      awards {
        desc
        name
        receivedDate
        receivedDateFmt
        image {
          altText
          variants {
            size
            url
          }
        }
      }
      images {
        altText
        variants {
          size
          url
        }
      }
      offerSummary {
        headline
        subheading
        offers {
          name
          headline
          desc
          image {
            altText
            variants {
              url
              size
            }
          }
          cta {
            button {
              adaDescription
              isNewWindow
              label
              url
            }
          }
        }
      }
      services {
        headline
        items {
          desc
          name
          image {
            altText
            variants {
              size
              url
            }
          }
        }
      }
      golfCourses {
        headline
        desc
        par
        holes
        image {
          variants {
            size
            url
          }
          altText
        }
        link {
          _id
          adaDescription
          isNewWindow
          label
          url
        }
      }
      features
    }
  }
}

      ${Golf_Gallery_FragmentFragmentDoc}`,
  originalOpName: 'getHotelGolfPage',
};
export function useGetHotelGolfPageQuery<TData = Types.GetHotelGolfPageQuery, TError = DXError>(
  variables: Types.GetHotelGolfPageQueryVariables,
  options?: Omit<UseQueryOptions<Types.GetHotelGolfPageQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.GetHotelGolfPageQuery, TError, TData>({
    queryKey: [GetHotelGolfPageDocument, variables],
    ...options,
  });
}
export function serverSideGetHotelGolfPageQuery(
  queryClient: QueryClient,
  variables: Types.GetHotelGolfPageQueryVariables,
  options?: FetchQueryOptions<Types.GetHotelGolfPageQuery>
) {
  return queryClient.fetchQuery<Types.GetHotelGolfPageQuery>({
    queryKey: [GetHotelGolfPageDocument, variables],
    ...options,
  });
}

export const GetHotelHomePageDocument = {
  operationName: 'hotel_brand',
  operationString: `query hotel_brand($ctyhocn: String!) {
  hotel(ctyhocn: $ctyhocn, language: "en") {
    hideGroupsBlock
    totalRooms
    partnerships {
      headline
      desc
      logoUrl
      relatedPolicy {
        terms {
          _id
          description {
            id
            value
          }
        }
      }
      images {
        altText
        caption
        captionLink
        variants {
          size
          url
        }
      }
      links {
        adaDescription
        isNewWindow
        label
        url
      }
    }
    name
    brandCode
    facilityOverview {
      headline
      shortDesc
      voucherUrl
      creditCardOnly
      isWorldwideResort
    }
    pets {
      ...HOTEL_PETS_INFO_FRAGMENT
    }
    creditCardTypes {
      name
    }
    overview {
      resortFeeDisclosureDesc
    }
    localization {
      currency {
        currencySymbol
        description
      }
      coordinate {
        ...HOTEL_COORDINATE_INFO
      }
    }
    safetyAndSecurity {
      hundredPctNonSmoking
      smokingPolicyDesc
      hasSmokingRooms
    }
    serviceAmenities(
      filter: {category: poi, distance_not: 0}
      sort: {order: asc, by: distance}
      first: 6
    ) {
      ...HOTEL_SERVICE_AMENITY_INFO
    }
    airports(sort: {order: asc, by: distance}, first: 3) {
      ...HOTEL_AIRPORT_INFO
    }
    images {
      gallery {
        hotelFeatures
        image(variant: galleryExpanded) {
          ...HOTEL_IMAGE_INFO_FRAGMENT
        }
      }
      carousel(imageVariant: propertyCarousel) {
        altText
        variants {
          size
          url
        }
      }
      fullWidthImageCarousel: carousel(ratios: [twentyOneByNine, threeByTwo]) {
        _id
        altText
        url
        ratios {
          _id
          url
          size
        }
      }
    }
    policy {
      parking {
        ...HOTEL_PARKING_INFO_FRAGMENT
      }
      smoking {
        smokingDesc
      }
      wifi {
        freeWlanMsg
        wlanInRoom
        wlanInRoomFee
        wlanInRoomFeeFmt
      }
      additionalPolicies(filter: {type: "propertyPolicy"}) {
        headline
        link {
          ...HOTEL_LINK_INFO_FRAGMENT
        }
        terms {
          components {
            id
            type
          }
          heading {
            id
            value
          }
          description {
            id
            value
          }
          unorderedList {
            id
            value
          }
          orderedList {
            id
            value
          }
        }
      }
    }
    spa {
      desc
      images {
        altText
        variants {
          size
          url
        }
      }
    }
    foodAndBeverage {
      numberOfRestaurants
    }
    golf {
      desc
      headline
      name
      awards {
        desc
        name
        receivedDate
        receivedDateFmt
        image {
          ...HOTEL_IMAGE_INFO_FRAGMENT
        }
      }
      images {
        altText
        variants {
          size
          url
        }
      }
    }
    meetings {
      hiltonLinkUrl
      eEventsUrl
      rfpUrl
      headline
      desc
      numberOfMeetingRooms
      largestMeetingRoomFeet: largestMeetingRoomAreaFmt(
        decimal: 0
        language: "en"
        strategy: round
        unit: feet
      )
      largestMeetingRoomMeters: largestMeetingRoomArea(unit: meter)
      totalAreaFeet: totalMeetingRoomsAreaFmt(
        decimal: 0
        language: "en"
        strategy: round
        unit: feet
      )
      totalAreaMeters: totalMeetingRoomsArea(unit: meter)
      image {
        ...HOTEL_IMAGE_INFO_FRAGMENT
      }
    }
    restaurantOverview {
      headline
      desc
      image {
        ...HOTEL_IMAGE_INFO_FRAGMENT
      }
      images(ratios: [threeByTwo]) {
        url
        altText
        caption
        ratios {
          url
          size
        }
      }
      restaurants(enhance: true) {
        description
        expandedImage: images(variant: diningExpanded) {
          altText
          variants {
            size
            url
          }
        }
        halfAndHalfImage: images(variant: halfAndHalf) {
          altText
          variants {
            size
            url
          }
        }
        link {
          adaDescription
          isNewWindow
          label
          url
        }
        name
        onSite
        overviewImage: images(variant: diningOverview) {
          altText
          variants {
            size
            url
          }
        }
        diningMenus {
          name
        }
        ...HOTEL_RESTAURANT_HOURS_OPERATION_FRAGMENT
      }
    }
    registration {
      ...HOTEL_REGISTRATION_INFO_FRAGMENT
    }
    structure {
      numRooms
      numFloors
    }
    contactInfo {
      emailAddress1
      emailAddress2
      marketingEmail
      networkDisclaimer
      phoneNumber
      phoneExtension
      faxNumber
    }
    accessibleFeatures: features(filter: {type_not: non_accessible}) {
      name
    }
    nonAccessibleFeatures: features(filter: {type: non_accessible}) {
      name
    }
    amenities {
      id
      name
    }
    video {
      id
      name
      source
      transcript
      url
    }
    videos {
      autoPlay
      url
      orientation
      posterImageUrl
      metadata {
        url
        label
        language
        type
      }
      source
    }
    roomTypeCategories {
      category
      desc
      headline
      image(ratios: [oneByOne, threeByTwo]) {
        altText
        url
        ratios {
          url
          size
        }
        variants {
          url
          size
        }
      }
      roomTypes {
        roomTypeCode
      }
    }
    propertyComponents {
      features {
        longDescription
        headline
        links {
          linkLabel
          linkUrl
          adaDescription
          isNewWindow
        }
        images(ratios: [threeByTwo]) {
          altText
          caption
          ratios {
            _id
            url
          }
        }
      }
      patchworkGrid {
        headline
        longDescription
        images(ratios: [oneByOne, twoByOne]) {
          altText
          caption
          ratios {
            size
            url
          }
        }
        links {
          isNewWindow
          linkLabel
          linkUrl
        }
      }
      quote {
        name_noTx: name
        quote_noTx: quote
        shortBio_noTx: shortBio
      }
    }
    ...TRIP_ADVISOR_FRAGMENT
  }
}

      ${Hotel_Pets_Info_FragmentFragmentDoc}
${Hotel_Coordinate_InfoFragmentDoc}
${Hotel_Service_Amenity_InfoFragmentDoc}
${Hotel_Airport_InfoFragmentDoc}
${Hotel_Image_Info_FragmentFragmentDoc}
${Hotel_Parking_Info_FragmentFragmentDoc}
${Hotel_Link_Info_FragmentFragmentDoc}
${Hotel_Restaurant_Hours_Operation_FragmentFragmentDoc}
${Hotel_Registration_Info_FragmentFragmentDoc}
${Trip_Advisor_FragmentFragmentDoc}`,
  originalOpName: 'getHotelHomePage',
};
export function useGetHotelHomePageQuery<TData = Types.GetHotelHomePageQuery, TError = DXError>(
  variables: Types.GetHotelHomePageQueryVariables,
  options?: Omit<UseQueryOptions<Types.GetHotelHomePageQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.GetHotelHomePageQuery, TError, TData>({
    queryKey: [GetHotelHomePageDocument, variables],
    ...options,
  });
}
export function serverSideGetHotelHomePageQuery(
  queryClient: QueryClient,
  variables: Types.GetHotelHomePageQueryVariables,
  options?: FetchQueryOptions<Types.GetHotelHomePageQuery>
) {
  return queryClient.fetchQuery<Types.GetHotelHomePageQuery>({
    queryKey: [GetHotelHomePageDocument, variables],
    ...options,
  });
}

export const GetHotelInfoPageDocument = {
  operationName: 'getHotelInfoPage',
  operationString: `query getHotelInfoPage($ctyhocn: String!) {
  hotel(ctyhocn: $ctyhocn, language: "en") {
    name
    brandCode
    hideGroupsBlock
    totalRooms
    airports {
      ...HOTEL_AIRPORT_INFO
    }
    restaurantOverview {
      desc
      restaurants(filter: {onSite: true}) {
        name
        description
        link {
          ...HOTEL_LINK_INFO_FRAGMENT
        }
        onSite
        ...HOTEL_RESTAURANT_HOURS_OPERATION_FRAGMENT
      }
    }
    diningDetails: foodAndBeverage {
      breakfastType
      hasFreeBreakfast
      hasRoomService
      servesBreakfast
    }
    freeBreakfastMessage: amenities(filter: {id: freeBreakfast}) {
      name
    }
    fitnessAndRecreation {
      fitnessCenter: servicesOffered(filter: {onsite: true, type: exerciseRoom}) {
        desc: onsiteServiceDesc
        hasCardioEquipment
        hasSauna
        hasSteamRoom
        hasWeightEquipment
        hoursOfOperation {
          sunday {
            open
            close
          }
          monday {
            open
            close
          }
          tuesday {
            open
            close
          }
          wednesday {
            open
            close
          }
          thursday {
            open
            close
          }
          friday {
            open
            close
          }
          saturday {
            open
            close
          }
        }
      }
      casino: recreationServices(filter: {type: "Casino"}) {
        desc: description
      }
      spaLocation: servicesOffered(filter: {type: spa}) {
        onsite
      }
      hasGolfCourse
      hasNearbyGolfCourse
    }
    pets {
      ...HOTEL_PETS_INFO_FRAGMENT
    }
    pools {
      poolDetails {
        desc: description
        isHeated
        isIndoor
        isOutdoor
        hoursOfOperation {
          monday {
            hoursOfOperation {
              open
              close
            }
          }
          tuesday {
            hoursOfOperation {
              open
              close
            }
          }
          wednesday {
            hoursOfOperation {
              open
              close
            }
          }
          thursday {
            hoursOfOperation {
              open
              close
            }
          }
          friday {
            hoursOfOperation {
              open
              close
            }
          }
          saturday {
            hoursOfOperation {
              open
              close
            }
          }
          sunday {
            hoursOfOperation {
              open
              close
            }
          }
        }
      }
    }
    spa {
      desc
      operatingHours {
        sunday
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
      }
    }
    golf {
      desc
    }
    accessibleFeatures: features(filter: {type_not: non_accessible}) {
      name
    }
    nonAccessibleFeatures: features(filter: {type: non_accessible}) {
      name
    }
    address {
      _id
      addressFmt
      addressLine1
      city
      stateName
      countryName
      country
      postalCode
      state
    }
    amenities {
      groups
      hint
      id
      name
      name_noTx: name
    }
    config {
      adjoiningRooms {
        active
      }
    }
    contactInfo {
      _id
      emailAddress1
      emailAddress2
      marketingEmail
      phoneNumber
      phoneExtension
      faxNumber
    }
    creditCardTypes {
      name
    }
    facilityOverview {
      creditCardOnly
    }
    images {
      gallery {
        fullImage: image(variant: galleryExpanded) {
          ...HOTEL_IMAGE_INFO_FRAGMENT
        }
        hotelFeatures
        hotelFeatures_noTx: hotelFeatures
      }
      ...MEETINGS_GALLERY_FRAGMENT
    }
    localization {
      currency {
        currencySymbol
        description
      }
    }
    policy {
      parking {
        ...HOTEL_PARKING_INFO_FRAGMENT
      }
      smoking {
        smokingDesc
      }
      wifi {
        freeWlanMsg
        wlanInRoom
        wlanInRoomFee
        wlanInRoomFeeFmt
      }
      additionalPolicies(
        filter: {type: "propertyPolicy", subType_not: "french-tourism-mediator-fr"}
      ) {
        headline
        link {
          ...HOTEL_LINK_INFO_FRAGMENT
        }
        terms {
          components {
            id
            type
          }
          heading {
            id
            value
          }
          description {
            id
            value
          }
          unorderedList {
            id
            value
          }
          orderedList {
            id
            value
          }
        }
      }
      additionalPoliciesFr: additionalPolicies(
        filter: {type: "propertyPolicy", subType: "french-tourism-mediator-fr"}
      ) {
        headline
        link {
          ...HOTEL_LINK_INFO_FRAGMENT
        }
        terms_noTx: terms {
          description {
            id
            value
          }
        }
      }
    }
    registration {
      ...HOTEL_REGISTRATION_INFO_FRAGMENT
    }
    safetyAndSecurity {
      hasSmokingRooms
      smokingPolicyDesc
    }
    ...HOTEL_MEETINGS_FRAGMENT
  }
}

      ${Hotel_Airport_InfoFragmentDoc}
${Hotel_Link_Info_FragmentFragmentDoc}
${Hotel_Restaurant_Hours_Operation_FragmentFragmentDoc}
${Hotel_Pets_Info_FragmentFragmentDoc}
${Hotel_Image_Info_FragmentFragmentDoc}
${Meetings_Gallery_FragmentFragmentDoc}
${Hotel_Parking_Info_FragmentFragmentDoc}
${Hotel_Registration_Info_FragmentFragmentDoc}
${Hotel_Meetings_FragmentFragmentDoc}`,
  originalOpName: 'getHotelInfoPage',
};
export function useGetHotelInfoPageQuery<TData = Types.GetHotelInfoPageQuery, TError = DXError>(
  variables: Types.GetHotelInfoPageQueryVariables,
  options?: Omit<UseQueryOptions<Types.GetHotelInfoPageQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.GetHotelInfoPageQuery, TError, TData>({
    queryKey: [GetHotelInfoPageDocument, variables],
    ...options,
  });
}
export function serverSideGetHotelInfoPageQuery(
  queryClient: QueryClient,
  variables: Types.GetHotelInfoPageQueryVariables,
  options?: FetchQueryOptions<Types.GetHotelInfoPageQuery>
) {
  return queryClient.fetchQuery<Types.GetHotelInfoPageQuery>({
    queryKey: [GetHotelInfoPageDocument, variables],
    ...options,
  });
}

export const GetHotelLayoutDocument = {
  operationName: 'getHotelLayout',
  operationString: `query getHotelLayout($ctyhocn: String!, $path: String!) {
  hotelPageTemplate(ctyhocn: $ctyhocn, language: "en", path: $path) {
    templateType
    breadCrumbs {
      name
      url
    }
    navigation {
      name
      name_noTx: name
      url
    }
    hotel {
      name
      brandCode
      propCode
      showMeetingsSimplified
      brand {
        _id
        formalName
        url
        phone {
          _id
          friendlyNumber
          number
        }
        socialMediaLinks {
          _id
          channel
          url
        }
        footer {
          _id
          sections {
            _id
            links {
              _id
              adaDescription
              isNewWindow
              label
              url
            }
          }
        }
        isPartnerBrand
      }
      amenities(filter: {id: newHotel}) {
        id
      }
      address {
        addressFmt
        addressStacked: addressFmt(format: "stacked")
        addressLine1
        mapCity
        country
        postalCode
        state
      }
      localization {
        currencyCode
        coordinate {
          ...HOTEL_COORDINATE_INFO
        }
      }
      contactInfo {
        phoneNumber
        emailAddress1
        emailAddress2
        phoneNumber
        phoneExtension
        faxNumber
      }
      images {
        carousel {
          url
        }
      }
      facilityOverview {
        homeUrl: homeUrlTemplate
        homeUrlPath: homeUrlPathTemplate
      }
      display {
        open
        openDate
        preOpenMsg
        resEnabled
        resEnabledDate
        treatments
      }
      shopAvailOptions {
        ageBasedPricing
        adultAge
        maxNumChildren
        maxNumOccupants
        maxNumRooms
        minArrivalDate
        maxArrivalDate
      }
      propertyComponents {
        theme {
          colors {
            primary
            primaryContrast
            secondary
            secondaryContrast
            tertiary
            tertiaryContrast
          }
        }
      }
    }
    seoMetaData {
      title
      keywords
      desc
    }
  }
}

      ${Hotel_Coordinate_InfoFragmentDoc}`,
  originalOpName: 'getHotelLayout',
};
export function useGetHotelLayoutQuery<TData = Types.GetHotelLayoutQuery, TError = DXError>(
  variables: Types.GetHotelLayoutQueryVariables,
  options?: Omit<UseQueryOptions<Types.GetHotelLayoutQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.GetHotelLayoutQuery, TError, TData>({
    queryKey: [GetHotelLayoutDocument, variables],
    ...options,
  });
}
export function serverSideGetHotelLayoutQuery(
  queryClient: QueryClient,
  variables: Types.GetHotelLayoutQueryVariables,
  options?: FetchQueryOptions<Types.GetHotelLayoutQuery>
) {
  return queryClient.fetchQuery<Types.GetHotelLayoutQuery>({
    queryKey: [GetHotelLayoutDocument, variables],
    ...options,
  });
}

export const GetHotelLocationPageDocument = {
  operationName: 'getHotelLocationPage',
  operationString: `query getHotelLocationPage($ctyhocn: String!) {
  hotel(ctyhocn: $ctyhocn, language: "en") {
    brandCode
    name
    display {
      resEnabled
    }
    localization {
      coordinate {
        ...HOTEL_COORDINATE_INFO
      }
    }
    onsiteFacilityServices {
      hasAirportShuttle
    }
    parking {
      ...HOTEL_PARKING_INFO_FRAGMENT
    }
    facilityOverview {
      locationShortDesc
    }
    serviceAmenities(filter: {category_in: poi}) {
      ...HOTEL_SERVICE_AMENITY_INFO
    }
    airports {
      ...HOTEL_AIRPORT_INFO
    }
  }
}

      ${Hotel_Coordinate_InfoFragmentDoc}
${Hotel_Parking_Info_FragmentFragmentDoc}
${Hotel_Service_Amenity_InfoFragmentDoc}
${Hotel_Airport_InfoFragmentDoc}`,
  originalOpName: 'getHotelLocationPage',
};
export function useGetHotelLocationPageQuery<
  TData = Types.GetHotelLocationPageQuery,
  TError = DXError
>(
  variables: Types.GetHotelLocationPageQueryVariables,
  options?: Omit<UseQueryOptions<Types.GetHotelLocationPageQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.GetHotelLocationPageQuery, TError, TData>({
    queryKey: [GetHotelLocationPageDocument, variables],
    ...options,
  });
}
export function serverSideGetHotelLocationPageQuery(
  queryClient: QueryClient,
  variables: Types.GetHotelLocationPageQueryVariables,
  options?: FetchQueryOptions<Types.GetHotelLocationPageQuery>
) {
  return queryClient.fetchQuery<Types.GetHotelLocationPageQuery>({
    queryKey: [GetHotelLocationPageDocument, variables],
    ...options,
  });
}

export const GetHotelRoomsPageDocument = {
  operationName: 'getHotelRoomsPage',
  operationString: `query getHotelRoomsPage($ctyhocn: String!) {
  hotel(ctyhocn: $ctyhocn, language: "en") {
    images {
      master {
        _id
        altText
        url
        variants {
          _id
          url
          size
        }
      }
    }
    roomTypeCategories {
      category
    }
    roomTypes {
      roomTypeName @toTitleCase
      carousel {
        url
      }
      roomTypeDesc
      bedType
      numBeds
      maxOccupancy
      features {
        name
        id
      }
    }
    ...TRIP_ADVISOR_FRAGMENT
  }
}

      ${Trip_Advisor_FragmentFragmentDoc}`,
  originalOpName: 'getHotelRoomsPage',
};
export function useGetHotelRoomsPageQuery<TData = Types.GetHotelRoomsPageQuery, TError = DXError>(
  variables: Types.GetHotelRoomsPageQueryVariables,
  options?: Omit<UseQueryOptions<Types.GetHotelRoomsPageQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.GetHotelRoomsPageQuery, TError, TData>({
    queryKey: [GetHotelRoomsPageDocument, variables],
    ...options,
  });
}
export function serverSideGetHotelRoomsPageQuery(
  queryClient: QueryClient,
  variables: Types.GetHotelRoomsPageQueryVariables,
  options?: FetchQueryOptions<Types.GetHotelRoomsPageQuery>
) {
  return queryClient.fetchQuery<Types.GetHotelRoomsPageQuery>({
    queryKey: [GetHotelRoomsPageDocument, variables],
    ...options,
  });
}

export const GetHotelRoomsDocument = {
  operationName: 'hotel_shopPropAvail',
  operationString: `query hotel_shopPropAvail($ctyhocn: String!, $arrivalDate: String!, $departureDate: String!, $numRooms: Int!, $numAdults: Int!, $numChildren: Int!, $specialRates: ShopSpecialRateInput, $guestLocationCountry: String, $guestId: BigInt, $knownGuest: Boolean, $programAccountId: BigInt, $childAges: [Int], $displayCurrency: String) {
  hotel(ctyhocn: $ctyhocn, language: "en") {
    config {
      adjoiningRooms {
        active
      }
    }
    shopAvail(
      input: {arrivalDate: $arrivalDate, departureDate: $departureDate, numRooms: $numRooms, numAdults: $numAdults, numChildren: $numChildren, specialRates: $specialRates, guestLocationCountry: $guestLocationCountry, guestId: $guestId, knownGuest: $knownGuest, programAccountId: $programAccountId, childAges: $childAges, displayCurrency: $displayCurrency}
    ) {
      notifications {
        subText
        subType
        title
        text
      }
      currencyCode
      roomTypes {
        quickBookRate {
          cashRatePlan
          roomTypeCode
          rateAmount
          rateChangeIndicator
          feeTransparencyIndicator
          cmaTotalPriceIndicator
          ratePlanCode
          rateAmountFmt(decimal: 0, strategy: trunc)
          rateAmountUSD: rateAmount(currencyCode: "USD")
          roomTypeCode
          amountAfterTaxFmt(decimal: 0, strategy: trunc)
          fullAmountAfterTax: amountAfterTaxFmt
          ratePlan {
            commissionable
            confidentialRates
            ratePlanName
            specialRateType
            hhonorsMembershipRequired
            redemptionType
            serviceChargesAndTaxesIncluded
          }
          serviceChargeDetails
          pointDetails(perNight: true) {
            pointsRate
            pointsRateFmt
          }
        }
        moreRatesFromRate {
          rateChangeIndicator
          feeTransparencyIndicator
          cmaTotalPriceIndicator
          roomTypeCode
          rateAmount
          rateAmountFmt(decimal: 0, strategy: trunc)
          amountAfterTaxFmt(decimal: 0, strategy: trunc)
          fullAmountAfterTax: amountAfterTaxFmt
          serviceChargeDetails
          ratePlanCode
          ratePlan {
            confidentialRates
            serviceChargesAndTaxesIncluded
          }
        }
        redemptionRoomRates(first: 1) {
          rateChangeIndicator
          pointDetails(perNight: true) {
            pointsRate
            pointsRateFmt
          }
          sufficientPoints
          pamEligibleRoomRate {
            ratePlan {
              ratePlanCode
              rateCategoryToken
              redemptionType
            }
            roomTypeCode
            sufficientPoints
          }
        }
        roomTypeCode
      }
      lowestPointsInc
    }
    roomTypeCategories {
      category
      desc
      headline
      image {
        ...HOTEL_IMAGE_INFO_FRAGMENT
      }
      roomTypes {
        ...RoomFragment
      }
    }
    roomTypes {
      ...RoomFragment
    }
  }
}

      ${Hotel_Image_Info_FragmentFragmentDoc}
${RoomFragmentFragmentDoc}`,
  originalOpName: 'getHotelRooms',
};
export function useGetHotelRoomsQuery<TData = Types.GetHotelRoomsQuery, TError = DXError>(
  variables: Types.GetHotelRoomsQueryVariables,
  options?: Omit<UseQueryOptions<Types.GetHotelRoomsQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.GetHotelRoomsQuery, TError, TData>({
    queryKey: [GetHotelRoomsDocument, variables],
    ...options,
  });
}
export function serverSideGetHotelRoomsQuery(
  queryClient: QueryClient,
  variables: Types.GetHotelRoomsQueryVariables,
  options?: FetchQueryOptions<Types.GetHotelRoomsQuery>
) {
  return queryClient.fetchQuery<Types.GetHotelRoomsQuery>({
    queryKey: [GetHotelRoomsDocument, variables],
    ...options,
  });
}

export const GetHotelSpaPageDocument = {
  operationName: 'getHotelSpaPage',
  operationString: `query getHotelSpaPage($ctyhocn: String!) {
  hotel(ctyhocn: $ctyhocn, language: "en") {
    brandCode
    contactInfo {
      emailAddress1
      emailAddress2
      phoneNumber
      phoneExtension
      faxNumber
    }
    images {
      ...SPA_GALLERY_FRAGMENT
    }
    spa {
      address {
        addressFmt
        addressStacked: addressFmt(format: "stacked")
        addressLine1
        addressLine2
        addressLine3
        addressLine4
        city
        country
        countryName
        county
        mapCity
        postalCode
        primeCity
        state
        stateName
      }
      awards {
        desc
        name
        receivedDate
        receivedDateFmt
        image {
          ...HOTEL_IMAGE_INFO_FRAGMENT
        }
      }
      contactDetails {
        information
        type
      }
      desc
      headline
      images {
        altText
        variants {
          size
          url
        }
      }
      link {
        ...HOTEL_LINK_INFO_FRAGMENT
      }
      offerSummary {
        headline
        offers {
          name
          cost
          desc
          headline
          shortDesc
          policies {
            name
            terms {
              description {
                id
                value
              }
            }
          }
          keySellingPoint
          cta {
            button {
              adaDescription
              isNewWindow
              label
              url
            }
          }
          image {
            ...HOTEL_IMAGE_INFO_FRAGMENT
          }
        }
        subheading
      }
      operatingHours {
        headline
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
      }
      services {
        headline
        items {
          name
          desc
          image {
            ...HOTEL_IMAGE_INFO_FRAGMENT
          }
        }
      }
      policies {
        terms {
          components {
            id
            index
            type
          }
          description {
            id
            value
          }
          heading {
            id
            value
          }
          orderedList {
            id
            value
          }
          unorderedList {
            id
            value
          }
        }
      }
    }
  }
}

      ${Spa_Gallery_FragmentFragmentDoc}
${Hotel_Image_Info_FragmentFragmentDoc}
${Hotel_Link_Info_FragmentFragmentDoc}`,
  originalOpName: 'getHotelSpaPage',
};
export function useGetHotelSpaPageQuery<TData = Types.GetHotelSpaPageQuery, TError = DXError>(
  variables: Types.GetHotelSpaPageQueryVariables,
  options?: Omit<UseQueryOptions<Types.GetHotelSpaPageQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.GetHotelSpaPageQuery, TError, TData>({
    queryKey: [GetHotelSpaPageDocument, variables],
    ...options,
  });
}
export function serverSideGetHotelSpaPageQuery(
  queryClient: QueryClient,
  variables: Types.GetHotelSpaPageQueryVariables,
  options?: FetchQueryOptions<Types.GetHotelSpaPageQuery>
) {
  return queryClient.fetchQuery<Types.GetHotelSpaPageQuery>({
    queryKey: [GetHotelSpaPageDocument, variables],
    ...options,
  });
}

export const GetHotelTemplateTypeDocument = {
  operationName: 'getHotelTemplateType',
  operationString: `query getHotelTemplateType($ctyhocn: String!) {
  hotelPageTemplate(ctyhocn: $ctyhocn, language: "en") {
    templateType
    hotel {
      facilityOverview {
        homeUrl: homeUrlTemplate
        homeUrlPath: homeUrlPathTemplate
      }
    }
  }
}`,
  originalOpName: 'getHotelTemplateType',
};
export function useGetHotelTemplateTypeQuery<
  TData = Types.GetHotelTemplateTypeQuery,
  TError = DXError
>(
  variables: Types.GetHotelTemplateTypeQueryVariables,
  options?: Omit<UseQueryOptions<Types.GetHotelTemplateTypeQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.GetHotelTemplateTypeQuery, TError, TData>({
    queryKey: [GetHotelTemplateTypeDocument, variables],
    ...options,
  });
}
export function serverSideGetHotelTemplateTypeQuery(
  queryClient: QueryClient,
  variables: Types.GetHotelTemplateTypeQueryVariables,
  options?: FetchQueryOptions<Types.GetHotelTemplateTypeQuery>
) {
  return queryClient.fetchQuery<Types.GetHotelTemplateTypeQuery>({
    queryKey: [GetHotelTemplateTypeDocument, variables],
    ...options,
  });
}
